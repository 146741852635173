// mini-sab
.mini-sab {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;

	@include media-breakpoint-down(lg) {
		margin-top: -10vh;
		z-index: 200;
	}
	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;
		border-radius: 12px;
		background: $green-1;
		box-shadow: 3px 3px 6px rgba($black, 0.15);
		@include media-breakpoint-down(lg) {
			margin: auto 1rem;
		}
		.container-holder {
			position: relative;
			margin: 3vh auto 5vh auto;
			padding: 15px 0;
			max-width: 75%;
			@include media-breakpoint-down(lg) {
				max-width: 85%;
			}
			color: #fff;
			.title {
				color: #fff;
				text-align: center;
				font-family: $font-family-secondary;
				font-size: 36px;
				font-style: normal;
				font-weight: 400;
				line-height: 64px;
				@include media-breakpoint-down(lg) {
					font-size: 24px;
					font-style: normal;
					font-weight: 400;
					line-height: 26px;
					max-width: 85%;
					margin: auto auto 0.7rem auto;
				}
			}
			.description {
				color: #fff;
				text-align: center;
				font-family: $font-family-primary;
				font-size: 20px;
				font-style: normal;
				font-weight: 400;
				line-height: 22px;
				@include media-breakpoint-down(lg) {
					color: #fff;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 22px;
				}
			}
		}
		@include media-breakpoint-up(lg) {
			padding-left: 30px;
			padding-right: 30px;
			z-index: 100;
			margin-top: 0;
		}
	}
}
