//header
&.w3-booking-widget-fullscreen-active {
	.header {
		z-index: 100;
	}
}
.header {
	z-index: 300;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	background: linear-gradient(to bottom, rgba(#000, 0.3) 40%, transparent);
	transition: 0.5s;

	&:has(.navbar-toggler[aria-expanded="true"]) {
		background: $light-green;
	}
	&.sticky-mobile {
		@include media-breakpoint-down(lg) {
			position: fixed !important;
			background: $light-green;
			transition: 0.5s ease;
			.logo {
				order: unset;
				max-width: 120px;
			}
		}
	}

	&.sticky {
		position: fixed;
		box-shadow: $shadow;
		background: $light-green;
		backdrop-filter: blur(4px);
		.navbar-nav {
			.nav-item {
				.dropdown-menu.show {
					background: $light-green !important;
				}
			}
			.nav-link {
				color: $black !important;
			}
		}
		.account-button a::after {
			color: $orange !important;
		}
	}

	.container,
	.container-fluid {
		padding-left: 2.5rem;
		padding-right: 2.5rem;
		@include media-breakpoint-down(lg) {
			padding-left: 1rem;
			padding-right: 1rem;
		}
		.container-holder {
			> .column {
				align-items: center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
					padding: 15px;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		.navbar-toggler {
			color: #fff;
			padding: 0;
			font-size: 20px;
			font-style: normal;
			font-weight: 400;
			line-height: 22px;
			i {
				transition: 0.5s ease;
			}
			&[aria-expanded="true"] {
				i {
					transform: rotate(-360deg);
					color: $green;
					transition: 0.5s ease;
					&::before {
						content: "\f00d";
					}
				}
			}
		}
	}

	// logo
	.logo {
		width: 260px;
		margin: 40px 10px 40px 0;

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
		@include media-breakpoint-down(lg) {
			order: 1;
			margin: 3vh auto;
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 2;
			padding: 6vh 1rem;

			.navbar-nav {
				.nav-item {
					border-bottom: 1px solid $primary;

					.nav-link {
						font-family: $font-family-primary;
						color: $black;
						font-size: 16px;
						font-weight: 600;
					}

					&.active {
						.nav-link {
							color: $orange !important;
							font-weight: 600;
						}
					}

					.dropdown-menu {
						position: static !important;
						border: 0 !important;
						box-shadow: none !important;
						margin: 0 !important;
						padding: 0 !important;
						transform: none !important;
						background: none !important;

						&::after,
						&::before {
							display: none;
						}

						.nav-item {
							border-bottom: 0;
							padding-left: 1rem;
						}

						.nav-link {
							font-size: 16px;
							font-weight: 200;
							font-family: $font-family-primary;
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 0;
			right: 0;
			margin: auto;
			max-width: fit-content;
			.navbar-nav {
				gap: 2rem;
				.nav-item {
					.nav-link {
						color: $white;
						color: #fff;
						font-family: $font-family-special;
						font-size: 16px;
						font-style: normal;
						font-weight: 600;
						line-height: 22px;
					}
					&.active,
					&:hover {
						.nav-link {
							text-decoration: underline;
							text-underline-offset: 5px;
						}
					}
					.dropdown-menu.show {
						border: 0;
						box-shadow: none;
						background: transparent;
						min-width: 13rem;
						text-align: center;
						padding: 1.5rem;
						border-radius: 12px;
						&::after,
						&::before {
							display: none;
						}
						.nav-link {
							text-decoration: none;
							&:hover {
								text-decoration: underline;
							}
						}
					}
				}
			}
		}
	}
	// book-button
	div.book-button {
		margin-left: auto;
		a {
			color: $orange;
			font-family: $font-family-special;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: 22px;
			text-decoration: none;
			text-underline-offset: 5px;
			&:hover {
				text-decoration: underline;
			}
		}
	}

	// site-switcher
	.site-switcher {
		margin: auto 1rem;
		&.mobile {
			display: none;
			@include media-breakpoint-down(lg) {
				display: block;
				.dropdown-toggle::after {
					display: none;
				}
			}
		}
		&.desktop {
			@include media-breakpoint-down(lg) {
				display: none;
			}
		}
	}
	// account button
	.account-button {
		a {
			.list-item-title {
				display: none;
			}
			&::after {
				content: "\f007";
				font-family: $font-awesome;
				color: #fff;
				font-size: 20px;
				font-style: normal;
				font-weight: 400;
				line-height: 22px;
			}
		}
	}
}
