&.accommodation-detail,
&.accommodation-object-detail {
    .lead-section {
        .title {
            font-size: inherit;
        }
    }

    .owl-carousel {
        .owl-nav {
            .owl-next,
            .owl-prev {
                font-size: 24px;
            }
        }
    }
}

&.accommodation-book {
    .container-fluid {
        padding-left: 15px;
        padding-right: 15px;
    }
}
