// container
.container-fluid {
	padding-left: 7.5rem;
	padding-right: 7.5rem;
	@include media-breakpoint-down(lg) {
		padding-left: 1rem;
		padding-right: 1rem;
	}
}
.container {
	&.container-small {
		@include media-breakpoint-up(sm) {
			max-width: 540px;
		}
	}

	&.container-medium {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}
	}

	&.container-large {
		@include media-breakpoint-up(lg) {
			max-width: 960px;
		}
	}

	&.container-intro {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}

		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}

		margin-top: 30px;
		text-align: center;
	}
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

//  images
.gallery-img {
	border-radius: 8px;
}

// default-card
.default-card {
	background: $white;
	border: 0;
	box-shadow: none;

	.card-image {
		border-radius: 12px;
	}

	.card-body {
		padding: 1rem;

		.card-title {
			color: #000;
			font-family: $font-family-special;
			font-size: 24px;
			font-style: normal;
			font-weight: 500;

			margin: 0 0 5px 0 !important;
		}

		.card-subtitle {
			@extend.card-subtitle-special;
			position: relative !important;
			padding: 5px 10px !important;
			right: 0 !important;
			top: 0 !important;
			background: none !important;
			padding: 0 !important;
			color: $green-1 !important;
		}

		.card-description {
			color: #000;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 22px;
			@extend .card-description-special;
		}

		.card-btn {
			background: none;
			padding: 0;
			border: none;
			color: $blue-1;
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: 22px;
			position: relative;

			&::after {
				content: "\f35a";
				font-family: $font-awesome;
				margin-left: 0.25rem;
				text-align: center;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				transition: 0.35s ease;
			}

			&:hover {
				&::after {
					margin-left: 0.75rem;
					transition: 0.35s ease;
				}
			}
		}
	}
}
.card-description-special {
	display: flex !important;
	flex-flow: column-reverse !important;
	position: relative !important;

	.list-icons {
		display: flex;
		column-gap: 1rem;
		margin: 0;

		.list-item {
			display: flex;
			align-items: center;
			column-gap: .25rem;
			padding: 0;

			.icon {
				position: static !important;
				font-size: 16px;

				i {
					font-weight: 400;
				}
			}
			.label {
				width: 1ch;
				height: 2ch;
				overflow: hidden;
				transform: translateY(-3px);
			}
		}
	}
}
.card-subtitle-special {
	max-width: max-content;
	border-radius: 6px;
	background: #23a137;
	display: block;
	color: #fff;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	padding: 10px 35px;
	font-family: $font-family-primary;
	position: absolute;
	top: 35px;
	right: 35px;
	@include media-breakpoint-down(lg) {
		padding: 5px;
		right: 5px;
		top: 15px;
	}
}

// title-link
.title-link {
	max-width: fit-content;
	line-height: 20px;
	color: $blue-1;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	text-decoration: none;

	a {
		display: flex;
		align-items: center;

		&::after {
			content: "\f35a";
			font-family: $font-awesome;
			margin-left: 0.5rem;
			text-align: center;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			transition: 0.35s ease;
		}

		&:hover {
			&::after {
				margin-left: 0.75rem;
				transition: 0.35s ease;
			}
		}
	}
}
