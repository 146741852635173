// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
$light-gray: #f8fceb;
$light-green: #d4e3a6;
$yellow: #ffce00;
$orange: #ec6719;
$green-2: #96c11f;
$green-1: #23a137;
$blue-1: #009fe3;
$blue-2: #3fc0f0;
$blue-3: #21468b;
$red-1: #ae1c28;
$white: #fff;
$black: #000;

// theme-colors
$primary: $green-1;
$secondary: $white;

// body
$body-bg: $light-gray;
$body-color: $black;

// links
$link-color: $primary;
$link-decoration: underline;
$link-hover-color: darken($link-color, 50%);

// typography
@import url("https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100..900;1,100..900&family=Radio+Canada:ital,wght@0,300..700;1,300..700&family=Yeseva+One&display=swap");
$font-family-primary: "Radio Canada", sans-serif;
$font-family-secondary: "Yeseva One", serif;
$font-family-special: "Hanken Grotesk", sans-serif;

$font-family-base: $font-family-primary;

$font-size-base: 0.875rem;

$h1-font-size: 2.5rem;
$h2-font-size: 2rem;
$h3-font-size: 1.75rem;
$h4-font-size: 1.5rem;
$h5-font-size: 1.25rem;
$h6-font-size: 1rem;

$headings-font-family: $font-family-secondary;
$headings-font-weight: 700;
$headings-color: $black;

// contextual
$light: $light-green;
$dark: $green-1;
$text-light: $white;
$text-dark: $black;

// matrix-colors
$matrix-color-primary: $primary;
$matrix-color-secondary: $secondary;

// shadow
$shadow: none;

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140px,
  xxxl: 1140px,
) !default;
*/
