// bg-*
// =========================================================================
section {
	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

.lead-section + .content-section,
.lead-section + .bundle-detail-section,
.lead-section + .bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}

// home usp section
.home-usp-section {
	margin: 3vh auto;
	overflow: hidden;
	.vocabulary-term-list {
		width: 100%;
		display: flex;
		justify-content: center;
		gap: 3rem;
		@include media-breakpoint-down(lg) {
			flex-flow: column;
			margin: auto;
			gap: 0.75rem;
			max-width: 90%;
		}
		li {
			display: flex;
			align-items: center;
			gap: 0.5rem;
			color: $green-1;
			font-family: $font-family-primary;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 22px;
			@include media-breakpoint-down(lg) {
			}
			i {
				font-size: 24px;
				font-style: normal;
				font-weight: 400;
				line-height: 22px;
				@include media-breakpoint-down(lg) {
					display: block;
					width: 3rem;
				}
			}
		}
	}
	.container-fluid {
		padding-right: 0 !important;
		margin: 6vh auto 0 auto;
		.container-holder .column {
			display: flex;
			flex-flow: row wrap;
			align-items: flex-end;
			gap: 0.5rem;
		}
		.title {
			color: #000;
			font-size: 32px;
			font-style: normal;
			font-weight: 400;
			line-height: 34px;
			max-width: fit-content;
			margin-bottom: 0;
		}
		.title-link {
			@include media-breakpoint-down(lg) {
				order: 1;
			}
		}
		.description {
			flex-basis: 100%;
			@include media-breakpoint-down(lg) {
				flex-basis: 90%;
			}
			p {
				color: $black;

				font-size: 20px;
				font-style: normal;
				font-weight: 400;
				line-height: 22px;
				max-width: 35%;
				@include media-breakpoint-down(lg) {
					max-width: 100%;
				}
			}
		}
		.slider {
			margin-top: 3vh;
			@include media-breakpoint-down(lg) {
				order: 2;
			}
			.item {
				.card {
					background: none;
					border: 0;
					box-shadow: none;
					.card-image {
						border-radius: 12px;
					}
					.card-body {
						padding: 1rem 0;
						.card-title {
							color: #000;
							font-family: $font-family-special;
							font-size: 24px;
							font-style: normal;
							font-weight: 500;

							margin: 0 0 5px 0 !important;
						}
						.card-subtitle {
							@extend.card-subtitle-special;
						}
						.card-description {
							color: #000;

							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							line-height: 22px;
							@extend .card-description-special;
						}
					}
				}
			}
		}
	}
}
// banner-section-home
.banner-section-home {
	margin: 6vh auto;
	position: relative;
	.container-fluid {
		@include media-breakpoint-down(lg) {
			padding-left: 0;
			padding-right: 0;
			overflow: hidden;
		}
		.owl-carousel {
			.owl-stage-outer {
				@include media-breakpoint-down(lg) {
					overflow: visible;
				}
			}
			.owl-item {
				min-height: 80vh;
				@include media-breakpoint-down(lg) {
					min-height: 40vh;
					max-height: 40vh;
					overflow: visible;
				}
				.item {
					border-radius: 12px;
					@include media-breakpoint-down(lg) {
						border-radius: 0;
					}

					.owl-title {
						color: $white;
						text-align: center;
						text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
						font-size: 64px;
						font-style: normal;
						font-weight: 400;
						line-height: 64px;
						@include media-breakpoint-down(lg) {
							text-align: center;
							font-size: 32px;
							font-style: normal;
							font-weight: 400;
							line-height: 32px;
						}
					}
					.owl-description {
						color: $white;
						text-align: center;
						font-size: 20px;
						font-style: normal;
						font-weight: 400;
						line-height: 22px;
						max-width: 75%;
						margin: auto;
						@include media-breakpoint-down(lg) {
							border-radius: 12px 12px 0 0;
							background: $green-1;
							position: absolute;
							bottom: -12vh;
							padding: 1.5rem;
							font-size: 16px;
							left: 50%;
							transform: translateX(-50%);
							min-width: calc(100% - 2rem);
							margin: 0 auto;
							p {
								max-width: 70%;
								margin: auto;
								text-align: center;
							}
						}
						@media (max-width: 360px) {
							bottom: -18vh;
						}
					}
				}
			}
		}
	}
	div.vocabulary-term-list {
		margin-top: -24vh;
		left: 0;
		width: 100%;
		z-index: 100;
		margin-bottom: 12vh;

		ul {
			display: flex;
			justify-content: center;
			gap: 1.5rem;
		}

		@include media-breakpoint-down(lg) {
			background: $green-1;
			margin-top: 0;
			border-radius: 0 0 12px 12px;
			padding: 0 0 1rem 0;
			max-width: calc(100% - 2rem);
			margin: 11vh auto 3vh auto;
		}
		@media (max-width: 360px) {
			margin-top: 18vh;
		}
		li {
			display: none;
			background: $white;
			width: 330px;
			height: 120px;
			flex-shrink: 0;
			border-radius: 6px;
			padding: 17px;
			position: relative;
			&:nth-child(1),
			&:nth-child(2),
			&:nth-child(3) {
				display: unset;
			}
			@include media-breakpoint-down(lg) {
				max-width: 90%;
			}
			a {
				display: flex;
				flex-flow: column;
				height: 100%;
				width: 100%;
				justify-content: flex-end;
				text-decoration: none;
				.list-item-title {
					order: 1;
					color: #000;
					font-family: $font-family-special;
					font-size: 24px;
					font-style: normal;
					font-weight: 700;
				}
				.list-item-suffix {
					color: #000;

					font-size: 14px;
					font-style: normal;
					font-weight: 400;
				}
			}
			&::after {
				content: "\f35a";
				color: #000;
				text-align: center;
				font-family: $font-awesome;
				font-size: 24px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				position: absolute;
				bottom: 15px;
				right: 15px;
				pointer-events: none;
				@include media-breakpoint-down(lg) {
					font-size: 18px;
				}
			}
			&:nth-child(3) {
				background: $blue-2;
				color: $white;
				transform: scale(1.05);
				transition: 0.5s ease;

				&::after {
					display: none;
				}
				h2 {
					order: 1;
					color: #000;
					font-family: $font-family-special;
					font-size: 24px;
					font-style: normal;
					font-weight: 700;
					color: $white;
				}
			}
			select#characteristic-select {
				width: 100%;
				height: 48px;
				border-radius: 6px;
				background: #fff;
				padding: 10px;
				border-right: 16px solid transparent;
			}
		}
	}
}

// home-facilities-section
.home-facilities-section {
	.container-fluid {
		margin: 6vh auto;

		.container-holder .column {
			display: flex;
			flex-flow: row wrap;
			align-items: flex-end;
			gap: 0.5rem;
		}

		.title {
			color: #000;
			font-size: 32px;
			font-style: normal;
			font-weight: 400;
			line-height: 34px;
			max-width: fit-content;
			margin-bottom: 0;
		}
		.title-link {
			@include media-breakpoint-down(lg) {
				order: 1;
			}
		}

		.description {
			flex-basis: 100%;

			p {
				color: $black;

				font-size: 20px;
				font-style: normal;
				font-weight: 400;
				line-height: 22px;
				max-width: 35%;
				@include media-breakpoint-down(lg) {
					max-width: 90%;
				}
			}
		}
	}
	.grid {
		width: 100%;
		margin: 3vh auto;
		@include media-breakpoint-down(lg) {
			order: 2;
		}
		.grid-items {
			display: flex !important;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			gap: 1rem;
			margin: 0 !important;
			@include media-breakpoint-down(lg) {
				gap: 10px;
			}
			.item {
				display: none;
				&:nth-child(-n + 4) {
					margin: 0;
					padding: 0;
					display: flex;
					max-width: 24% !important;
					flex-basis: 24% !important;
					flex: unset !important;
					@include media-breakpoint-down(lg) {
						max-width: 48% !important;
						flex-basis: 48% !important;
					}
					.card {
						width: 100%;
						border-radius: 6px;
						background: #fff;
						border: 0;
						padding: 1rem;
						position: relative;
						&::after {
							content: "\f35a";
							color: #000;
							text-align: center;
							font-family: $font-awesome;
							font-size: 24px;
							font-style: normal;
							font-weight: 400;
							line-height: normal;
							position: absolute;
							bottom: 15px;
							right: 15px;
							@include media-breakpoint-down(lg) {
								font-size: 18px;
							}
						}
						.card-image {
							text-align: left !important;
							margin-bottom: 1rem;
							i {
								color: $blue-2;

								font-size: 24px;
								font-style: normal;
								font-weight: 400;
								line-height: 22px;
							}
						}
						.card-body {
							max-width: 75%;
							padding: 0;
							.card-caption {
								display: flex;
								flex-flow: column-reverse;
								.card-subtitle {
									color: #000;
									font-family: $font-family-primary;
									font-size: 14px;
									font-style: normal;
									font-weight: 400;
									margin-bottom: 0;
									transition: 0.35s ease;
								}
								.card-title {
									color: #000;
									font-family: $font-family-special;
									font-size: 24px;
									font-style: normal;
									font-weight: 500;
									line-height: normal;
									transition: 0.35s ease;
									@include media-breakpoint-down(lg) {
										font-size: 18px;
									}
								}
							}
						}
						&:hover {
							background: $blue-2;
							.card-image i,
							.card-title,
							.card-subtitle {
								color: $white !important;
								transition: 0.35s ease;
							}
							&::after {
								transform: scale(1.05);
								color: $white;
							}
						}
					}
				}
			}
		}
	}
	.owl-carousel.large {
		@include media-breakpoint-down(lg) {
			order: 3;
			min-height: unset;
		}
		.item {
			padding: 0 !important;
			@include media-breakpoint-down(lg) {
				min-height: unset;
			}
			.card {
				background: none;
				border: 0;
				box-shadow: none;

				.card-image {
					border-radius: 12px;
				}

				.card-body {
					padding: 1rem 0;
					.card-caption {
						flex: unset;
					}
					.card-title {
						color: #000;
						font-family: $font-family-special;
						font-size: 24px;
						font-style: normal;
						font-weight: 500;

						margin: 0.5rem 0 !important;
					}

					.card-description {
						color: #000;
						max-width: 55%;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 22px;
						@extend .card-description-special;
						@include media-breakpoint-down(lg) {
							max-width: 100%;
						}
					}
					.card-subtitle {
						@extend.card-subtitle-special;
					}
					.card-btn {
						background: none;
						padding: 0;
						border: none;
						color: $blue-1;
						font-size: 14px;
						font-style: normal;
						font-weight: 700;
						line-height: 22px;
						position: relative;
						&::after {
							content: "\f35a";
							font-family: $font-awesome;
							margin-left: 0.25rem;
							text-align: center;
							font-size: 16px;
							font-style: normal;
							font-weight: 400;
							line-height: normal;
							transition: 0.35s ease;
						}
						&:hover {
							&::after {
								margin-left: 0.75rem;
								transition: 0.35s ease;
							}
						}
					}
				}
			}
		}
	}
}

.home-cta-section {
	margin-bottom: 6vh;
	.slider.cta {
		.item {
			@include media-breakpoint-down(lg) {
				height: auto !important;
			}
		}
		.card {
			border: none;
			border-radius: 12px;
			background: $green-1;
			@include media-breakpoint-down(lg) {
				display: flex;
				flex-flow: column !important;
			}
			.card-image,
			.card-body {
				flex-basis: 50% !important;
				max-width: 50% !important;
				@include media-breakpoint-down(lg) {
					max-width: 100% !important;
					flex-basis: 100% !important;
				}
			}
			.card-body {
				padding-left: 1.5rem;
				padding-right: 1.5rem;
				padding-bottom: 1.5rem;
				.card-description {
					@include media-breakpoint-down(lg) {
						display: flex;
						flex-flow: column;
					}
				}
			}
			.card-image {
				position: unset !important;
				@include media-breakpoint-down(lg) {
					text-align: left !important;
					order: -1;
				}
				i {
					color: #fff;
					font-size: 24px;
					font-style: normal;
					font-weight: 400;
					position: absolute;
					top: 35px;
					left: calc(50% + 1.5rem);
					transition: 0.35s ease;
					@include media-breakpoint-down(lg) {
						position: relative;
						top: unset;
						left: unset;
					}
				}
			}
			.card-title {
				max-width: 45%;
				position: absolute;
				left: 1.5rem;
				top: 30px;
				color: #fff;
				font-size: 32px;
				font-style: normal;
				font-weight: 400;
				@include media-breakpoint-down(lg) {
					max-width: 100%;
					position: relative;
					left: 0;
					top: 0;
				}
			}
			.card-description {
				margin-top: 60px;
				color: #fff;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				@include media-breakpoint-down(lg) {
					margin-top: 0.5rem;
				}
			}
			.card-btn {
				background: none;
				text-transform: none;
				border: 0;
				padding: 0;
				color: #fff;
				font-family: $font-family-special;
				font-size: 24px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				&::after {
					content: "\f35a";
					font-family: $font-awesome;
					margin-left: 0.5rem;
					text-align: center;
					font-size: 24px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					transition: 0.35s ease;
				}
			}
			&:hover {
				.card-image i {
					font-weight: 600;
					transition: 0.35s ease;
					transform: scale(1.25);
				}
				.card-btn::after {
					transform: translateX(0.35rem);
					transition: 0.35s ease;
				}
			}
		}
	}
}

.home-deals-section {
	overflow: hidden;
	.container-fluid {
		padding-right: 0;
	}

	.container-holder .column {
		display: flex;
		flex-flow: row wrap;
		align-items: flex-end;
		gap: 0.5rem;
	}

	.title {
		color: #000;
		font-size: 32px;
		font-style: normal;
		font-weight: 400;
		line-height: 34px;
		max-width: fit-content;
		margin-bottom: 0;
	}
	.title-link {
		@include media-breakpoint-down(lg) {
			order: 1;
		}
	}

	.description {
		flex-basis: 100%;
		p {
			color: $black;
			font-size: 20px;
			font-style: normal;
			font-weight: 400;
			line-height: 22px;
			max-width: 35%;
			@include media-breakpoint-down(lg) {
				max-width: 90%;
			}
		}
	}

	.slider {
		margin-top: 3vh;
		@include media-breakpoint-down(lg) {
			order: 3;
		}

		.item {
			.card {
				background: none;
				border: 0;
				box-shadow: none;

				.card-image {
					border-radius: 12px;
				}

				.card-body {
					padding: 1rem 0;

					.card-title {
						color: #000;
						font-family: $font-family-special;
						font-size: 24px;
						font-style: normal;
						font-weight: 500;

						margin: 0 0 5px 0 !important;
					}
					.card-subtitle {
						@extend.card-subtitle-special;
					}
					.card-description {
						color: #000;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 22px;
						@extend .card-description-special;
					}

					.card-btn {
						background: none;
						padding: 0;
						border: none;
						color: $blue-1;
						font-size: 14px;
						font-style: normal;
						font-weight: 700;
						line-height: 22px;
						position: relative;

						&::after {
							content: "\f35a";
							font-family: $font-awesome;
							margin-left: 0.25rem;
							text-align: center;
							font-size: 16px;
							font-style: normal;
							font-weight: 400;
							line-height: normal;
							transition: 0.35s ease;
						}

						&:hover {
							&::after {
								margin-left: 0.75rem;
								transition: 0.35s ease;
							}
						}
					}
				}
				&.icon {
					position: relative;
					border-radius: 12px;
					width: 100%;

					.card-image {
						text-align: left !important;
						margin-bottom: 0.5rem;
						i {
							color: $white;

							font-size: 24px;
							font-style: normal;
							font-weight: 400;
							line-height: 22px;
						}
					}
					.card-body {
						position: absolute;
						background: $blue-2;
						top: 0;
						width: 100%;
						aspect-ratio: 1/1.47;
						border-radius: 12px;
						.card-caption {
							padding: 1rem 1.5rem;
							border-radius: 12px;
							display: flex;
							flex-flow: column;
							justify-content: space-between;
							.card-title {
								font-family: $font-family-secondary;
								color: #fff;
								font-size: 32px;
								font-style: normal;
								font-weight: 400;
								line-height: normal;
								@include media-breakpoint-down(lg) {
									font-size: 20px;
								}
							}
							.card-description {
								margin-top: auto;
								color: #fff;
								font-size: 14px;
								font-style: normal;
								font-weight: 400;
								line-height: 18px;
								max-width: 80%;
							}
						}
						.card-buttons {
							padding: 0 1.5rem;
							.card-btn {
								color: #fff;
								font-family: $font-family-special;
								font-size: 24px;
								font-style: normal;
								font-weight: 500;
								line-height: normal;
								width: 100%;
								display: flex;
								justify-content: space-between;
								align-items: center;
								&::after {
									font-size: 24px;
									font-style: normal;
									font-weight: 400;
								}
							}
						}
					}
				}
			}
		}
	}
	div.read-more {
		margin: 3vh auto 3vh 0;
		@include media-breakpoint-down(lg) {
			order: 4;
		}
		a {
			@extend.btn, .btn-primary;
		}
	}
}

// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}
	.title,
	.title *,
	.page-title,
	.page-title * {
		font-size: 32px;
		font-style: normal;
		font-weight: 400;
		max-width: fit-content;
	}

	.subtitle * {
		font-family: $font-family-special;
		font-weight: 400;
		font-size: $h4-font-size;
	}

	.info {
		// accommodation

		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .list-unstyled;

			li {
				margin: 0 15px 0 0;
				font-size: 16px;
				color: $green-1;
			}

			.icon {
				margin-right: 5px;
				i {
					font-weight: 200;
				}
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-6vh + 30px);
	margin-bottom: calc(-6vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}
