// footer
.footer {
	background: $light-green;
	margin-top: 6vh;
	.footer-cta {
		min-height: 12vh;
		background: $green-1;
		padding: 1rem 0;
		position: relative;
		&::before {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			height: 100%;
			width: 70px;
			background: url("/images/footer-cta-left.svg");
			background-size: 100%;
			background-repeat: no-repeat;
			background-position: bottom;
		}
		&::after {
			content: "";
			position: absolute;
			right: 0;
			bottom: 0;
			height: 100%;
			width: 70px;
			background: url("/images/footer-cta-right.svg");
			background-size: 100%;
			background-repeat: no-repeat;
			background-position: bottom;
		}
		.column {
			display: flex;
			flex-flow: row;
			justify-content: center;
			align-items: center;
			gap: 1.5rem;
			@include media-breakpoint-down(lg) {
				flex-flow: column;
				gap: 0.75rem;
				padding: 3vh 0;
			}
			.footer-text {
				max-width: 30%;
				color: $white;
				text-align: left;
				@include media-breakpoint-down(lg) {
					max-width: 80%;
					text-align: center;
				}
				h6,
				h5,
				h4,
				h3,
				h2,
				h1 {
					color: $white;
					color: #fff;
					font-size: 32px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
				}
				p {
					color: #fff;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 22px;
				}
				a {
					border-radius: 6px;
					background: #fff;
					display: block;
					color: #000;
					font-family: "Radio Canada";
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 22px;
					padding: 10px 25px;
					min-width: 330px;
					text-decoration: none;
					display: flex;
					align-items: center;
					justify-content: space-between;
					@include media-breakpoint-down(lg) {
						max-width: 100%;
						min-width: 100%;
					}
					&::after {
						content: "\f35a";
						font-family: $font-awesome;
						margin-left: 0.5rem;
						text-align: center;
						font-size: 24px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
						transition: 0.35s ease;
					}

					&:hover {
						background: $light-green;
						color: $white;
						&::after {
							transform: translateX(5px);
							transition: 0.35s ease;
							color: $white;
						}
					}
				}
			}
			.vocabulary-term-list {
				display: flex;
				gap: 0.8rem;
				align-items: center;
				height: 100%;
				li {
					display: flex;
					align-items: center;
					.list-item-title {
						display: none;
					}
					i {
						color: #fff;
						font-size: 32px;
						font-style: normal;
						font-weight: 400;
						margin-top: -15px;
						transition: 0.35s ease;
					}
					&:hover {
						i {
							transform: translateY(-5px);
							transition: 0.35s ease;
						}
					}
				}
			}
		}
	}
	.footer-main {
		padding: 6 vh 0 0 0;
		.container {
			margin-top: 6vh;
			.container-holder {
				@include media-breakpoint-down(lg) {
					display: flex;
					flex-flow: column;
					.logo {
						img {
							max-width: 250px;
							margin: auto;
						}
					}
				}
			}
		}
		.container-fluid {
			margin-top: 6vh;
			.column {
				@include media-breakpoint-down(lg) {
					max-width: 100% !important;
					flex-basis: 100% !important;
				}
				.list {
					max-width: fit-content;
					margin-left: auto;
					margin-right: 1.5rem;
					display: flex;
					gap: 1rem;
					padding: 15px;
					@include media-breakpoint-down(lg) {
						margin-right: auto;
					}
					a {
						color: #000;

						font-size: 12px;
						font-style: normal;
						font-weight: 400;
						line-height: 22px;
						text-decoration: none;
					}
				}
			}
		}
		.one {
			max-width: 53% !important;
			flex-basis: 53% !important;
			@include media-breakpoint-down(lg) {
				max-width: 100% !important;
				flex-basis: 100% !important;
			}
			.footer-text {
				h6,
				h5,
				h4,
				h3,
				h2,
				h1 {
					color: #000;
					font-size: 32px;
					font-style: normal;
					font-weight: 400;
					line-height: 64px;
					@include media-breakpoint-down(lg) {
						font-size: 24px;
						line-height: normal;
					}
				}
				p {
					color: #000;

					font-size: 20px;
					font-style: normal;
					font-weight: 400;
					line-height: 22px;
				}
				ul {
					list-style: none;
					padding: 0;
					margin: 1.5rem 0;
					li {
						color: #000;

						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 22px;
					}
				}
				a {
					color: #000;

					font-size: 20px;
					font-style: normal;
					font-weight: 400;
					line-height: 30px;
					text-decoration-line: underline;
				}
			}
		}
		.two,
		.three {
			max-width: 22% !important;
			flex-basis: 22% !important;
			@include media-breakpoint-down(lg) {
				max-width: 100% !important;
				flex-basis: 100% !important;
			}
			.footer-list {
				padding-top: 32px;
				@include media-breakpoint-down(lg) {
					padding-top: 1rem;
				}
				h5 {
					display: none;
				}
				a {
					color: #000;
					font-size: 14px;
					font-style: normal;
					font-weight: 700;
					line-height: 22px;
					text-decoration: none;
					margin-bottom: 0.5rem;
					&::after {
						content: "\f35a";
						font-family: $font-awesome;
						margin-left: 0.5rem;
						text-align: center;
						font-size: 16px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
						transition: 0.35s ease;
					}

					&:hover {
						text-decoration: underline;
						text-underline-offset: 5px;
						&::after {
							margin-left: 0.75rem;
							transition: 0.35s ease;
						}
					}
				}
			}
		}
	}
}
